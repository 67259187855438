import React from "react";
import Page from "../layouts/Page";
import Something from "../media/illustration/Something.png";
import _ from "lodash";

export default class ErrorPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: (typeof window !== "undefined" && window.LAYOUT) || "grid"
    };
  }

  render() {
    return (
      <Page title="Cette page n'existe pas" description="Cette page n'existe pas.">
        <section className="section">
          <div className="container">
            <div class="columns">
              <div class="column is-6">
                <div class="content is-medium">
                    <h1 class="title">Oups, cette page ne fonctionne pas.</h1>
                    <p><a class="button is-danger" href="/">Retour à la page d'accueil.</a></p>
                </div>
              </div>
              <div class="column is-5">
                <div class="content is-medium">
                    <figure class="image">
                    <img src={Something} alt="Something happened" />
                    </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    );
  }
}
